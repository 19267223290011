.App {
  background: #fafafa;
}

.leaflet-container {
  height: 400px;
  width: 50%;
  margin: 0 auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ui.stacked.segment:after {
  content: none;
}

.cards-services {
  height: 250px;
  color: white;
  border-radius: 5px;
  justify-content: center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 2rem;
}

.text-card-services {
  color: white !important;
}

.text-color-kimvex {
  color: #15bfc2 !important;
}

.image-cover {
  background-size: cover !important;
  width: 100%;
  height: 300px;
  border-radius: 5px;
  padding: 0.5rem;
}

.border-image-profile-shop {
  border: 3px solid white !important;
}

.small-div {
  position: absolute;
  bottom: 0px;
}

.searcher-for-map {
  width: 100%;
  margin-bottom: 0.5rem;
}

.index-buscador {
  z-index: 1001;
}

.index-modal {
  z-index: 1002 !important;
}

.header-pay-card {
  font-size: 2rem !important;
  font-weight: 700 !important;
}

.list-choise-plan ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-choise-plan ul li ul li {
  margin-left: 1.5rem;
  padding: 0;
}

.section-step-sale {
  background: white !important;
  border: 1px solid !important;
  border-color: #d4d4d5 !important;
}

.drop-zone {
  border: 3px dashed teal;
  width: 100%;
  height: 70px;
  margin-bottom: 1rem;
}

.form-control {
  width: 80px !important;
  text-align: center;
}

.remove-overflow {
  overflow: hidden;
}

.menu-responsive {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50%;
}

.menu-responsive a {
  color: teal !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2rem;
}

.kmv-link-development {
  color: white;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.kmv-link-development:hover {
  color: white;
  text-decoration: underline;
}

/** Initial page **/
.container-complete {
  width: 100%;
  margin: 0px;
  background-color: #15bfc2;
}

.container-initial-page {
  width: 80%;
  margin: 0rem auto;
  height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.container-title-inital-page {
  width: 350px;
  height: 150px;
  border: 3px solid white;
  border-radius: 3px;
  text-align: center;
}

.title-initial-page {
  color: white;
  font-size: 7.5rem;
  font-weight: 700;
  font-family: 'Poiret One', cursive;
}

.information-of-service {
  display: flex;
  flex-direction: column;
  width: 450px;
}

.information-text {
  font-family: 'Poiret One', cursive;
  font-size: 2.5rem;
  color: white;
}

.container-go-services {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 350px;
}

.go-services {
  background: white;
  color: teal;
  width: 155px;
  padding: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.go-services:hover {
  box-shadow: 2px 5px 6px -4px black;
  color: teal;
}

.image-go-services {
  width: 15px;
  height: 15px;
}

.second-information-section {
  width: 100%;
  background-color: white;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  justify-content: center;
  align-items: center;
}

.sub-title-information {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  margin: 0rem 0rem 1rem 0rem;
  border-bottom: 2px solid teal;
}

.information-step-solds {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
  margin: 0rem auto;
  align-items: baseline;
}

.third-section-plans {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  padding: 2rem 0rem;
  align-items: center;
}

.header-table-plans {
  text-align: center !important;
}

.quarter-section {
  width: 100%;
  height: 350px;
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem;
}

.refer-win-money-container {
  width: 60%;
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
}

.img-money {
  width: 200px;
  height: 300px;
}

.text-money {
  width: 600px;
  margin: 1rem 0rem 0rem 3rem;
}

.title-money {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
}

.information-money {
  font-family: 'Poiret One', cursive;
  font-size: 1.4rem;
}

.footer-page {
  display: flex;
  flex-direction: row;
  height: 250px;
  padding: 0rem 3rem 0rem 3rem;
  justify-content: space-around;
}

.footer-information-kimvex {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.kimvxe-logo {
  color: white;
  border: 2px solid white;
  font-size: 2rem;
  padding: 0.5rem;
  font-family: 'Poiret One', cursive;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.kmv-link {
  color: white;
  font-size: 1.5rem;
}

.kmv-link:hover {
  color: white;
  text-decoration: underline;
}

.contact-information-kimvex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.contact-us {
  color: white;
  font-size: 1.2rem;
}

.social-networks-kimvex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
}

.social-networks-item {
  font-size: 1.3rem;
  color: white;
  margin-bottom: 1rem;
}

.twitter:hover {
  color: #1ea1f2;
}

.copyright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
}

.information-copyright {
  color: white;
}

/* Version tablet*/
.container-initial-page-tablet {
  width: 95%;
  margin: 0rem auto;
  height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.container-title-inital-page-tablet {
  width: 300px;
  height: 100px;
  border: 3px solid white;
  border-radius: 3px;
  text-align: center;
}

.title-initial-page-tablet {
  color: white;
  font-size: 5rem;
  font-weight: 700;
  font-family: 'Poiret One', cursive;
}

.information-of-service {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.information-text {
  font-family: 'Poiret One', cursive;
  font-size: 2.5rem;
  color: white;
}

.second-information-section-tablet {
  width: 100%;
  background-color: white;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  justify-content: center;
  align-items: center;
}

.information-step-solds {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 95%;
  margin: 0rem auto;
  align-items: baseline;
}

.card-size {
  width: 200px !important;
  height: 150px;
}

.third-section-plans {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  padding: 2rem 0rem;
  align-items: center;
}

/* version movile */
.container-initial-page-movile {
  width: 100%;
  margin: 0rem auto;
  height: 300px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-around;
}

.container-title-inital-page-movile {
  width: 200px;
  height: 80px;
  border: 3px solid white;
  border-radius: 3px;
  text-align: center;
}

.title-initial-page-movile {
  color: white;
  font-size: 4rem;
  font-weight: 700;
  font-family: 'Poiret One', cursive;
}

.information-of-service {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.information-text {
  font-family: 'Poiret One', cursive;
  font-size: 2rem;
  color: white;
}

.second-information-section-movile {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  justify-content: center;
  align-items: center;
}

.information-step-solds-movile {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0rem auto;
  align-items: center;
}

.card-size {
  width: 90% !important;
  height: 150px;
}

.steps-cards {
  font-size: 2rem !important;
}

.date-movile {
  font-size: 1.5rem !important;
}

.description-card-movile {
  font-size: 1.4rem !important;
}

.third-section-plans {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  padding: 2rem 0rem;
  align-items: center;
}

.quarter-section-movile {
  width: 100%;
  height: 330px;
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 1.5rem 0rem;
}

.refer-win-money-container-movile {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.img-money-movile {
  width: 150px;
  height: 270px;
}

.text-money-movile {
  width: 200px;
  margin: 1rem 0rem 0rem 1.5rem;
}

.title-money-movile {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
}

.information-money-movile {
  font-family: 'Poiret One', cursive;
  font-size: 1.4rem;
}

.footer-page-movile {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  align-items: center;
  justify-content: space-around;
}

.footer-information-kimvex-movile {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0rem;
}

.contact-information-kimvex-movile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 1rem 0rem;
}

.social-networks-kimvex-movile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 1rem 0rem;
}

.copyright-movile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 1rem 0rem;
}

/* mini */
.container-initial-page-movile-mini {
  width: 100%;
  margin: 0rem auto;
  height: 300px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-around;
}

.container-title-inital-page-movile-mini {
  width: 200px;
  height: 80px;
  border: 3px solid white;
  border-radius: 3px;
  text-align: center;
}

.title-initial-page-movile-mini {
  color: white;
  font-size: 4rem;
  font-weight: 700;
  font-family: 'Poiret One', cursive;
}

.information-of-service-movile-mini {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.information-text {
  font-family: 'Poiret One', cursive;
  font-size: 2rem;
  color: white;
}

.container-go-services-movile-mini {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.go-services-mini {
  background: white;
  color: teal;
  width: 145px;
  padding: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 0.9rem;
}

.second-information-section-movile-mini {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  justify-content: center;
  align-items: center;
}

.information-step-solds-movile-mini {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0rem auto;
  align-items: center;
}

.card-size {
  width: 90% !important;
  height: 150px;
}

.steps-cards {
  font-size: 2rem !important;
}

.date-movile-mini {
  font-size: 1.5rem !important;
}

.description-card-movile-mini {
  font-size: 1.4rem !important;
}

.third-section-plans {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  padding: 2rem 0rem;
  align-items: center;
}

.quarter-section-movile-mini {
  width: 100%;
  height: 280px;
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 1.5rem 0rem 0rem 0rem;
}

.refer-win-money-container-movile-mini {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 230px;
}

.img-money-movile-mini {
  width: 120px;
  height: 220px;
}

.text-money-movile-mini {
  width: 200px;
  margin: 1rem 0rem 0rem 1rem;
}

.title-money-movile-mini {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
}

.information-money-movile-mini {
  font-family: 'Poiret One', cursive;
  font-size: 1.2rem;
}

.footer-page-movile-mini {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  align-items: center;
  justify-content: space-around;
}

.footer-information-kimvex-movile-mini {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0rem;
}

.contact-information-kimvex-movile-mini {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 1rem 0rem;
}

.social-networks-kimvex-movile-mini {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 1rem 0rem;
}

.copyright-movile-mini {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 1rem 0rem;
}
